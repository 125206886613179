import { ENUM_NOTIFICATION_TAG, NotificationTagColorProps } from "./interface"

export const NotificationTagLabel: Record<ENUM_NOTIFICATION_TAG, string> = {
    [ENUM_NOTIFICATION_TAG.ACCOUNT]: "Tài khoản",
    [ENUM_NOTIFICATION_TAG.IMPORTANT]: "Quan trọng",
    [ENUM_NOTIFICATION_TAG.ORDER]: "Đơn hàng",
    [ENUM_NOTIFICATION_TAG.PRICE]: "Đơn giá",
    [ENUM_NOTIFICATION_TAG.PRODUCT]: "Sản phẩm",
    [ENUM_NOTIFICATION_TAG.PROMOTION]: "Khuyến mãi",
    [ENUM_NOTIFICATION_TAG.TICKET]: "Phiếu hỗ trợ",
    [ENUM_NOTIFICATION_TAG.FAVORITE]: "Yêu thích",
}

export const NotificationTagColor: Record<ENUM_NOTIFICATION_TAG, NotificationTagColorProps> = {
    [ENUM_NOTIFICATION_TAG.ACCOUNT]: {
        text: "#D55D2A",
        background: "#FFF4EF",
        border: "#D55D2A"
    },
    [ENUM_NOTIFICATION_TAG.IMPORTANT]: {
        text: "#D4323B",
        background: "#FFF4EF",
        border: "#D4323B"
    },
    [ENUM_NOTIFICATION_TAG.ORDER]: {
        text: "#09884D",
        background: "#EDFDF6",
        border: "#0CBA69"
    },
    [ENUM_NOTIFICATION_TAG.PRICE]: {
        text: "#0E1983",
        background: "#F9F9FF",
        border: "#0E1983"
    },
    [ENUM_NOTIFICATION_TAG.PRODUCT]: {
        text: "#6C3B0D",
        background: "rgba(108, 59, 13, 0.05)",
        border: "#6C3B0D"
    },
    [ENUM_NOTIFICATION_TAG.PROMOTION]: {
        text: "#F9B710",
        background: "#fffbf3",
        border: "#F9B710"
    },
    [ENUM_NOTIFICATION_TAG.TICKET]: {
        text: "#783DC3",
        background: "#F9EFFF",
        border: "#783DC3"
    },
    [ENUM_NOTIFICATION_TAG.FAVORITE]: {
        text: "rgb(147, 60, 136)",
        background: "rgb(207 162 199 / 5%)",
        border: "rgb(147, 60, 136)"
    },
}