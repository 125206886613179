import { isValid } from 'clients/Clients';
import { useEffect, useState } from 'react';
import mapDataProductV2 from 'services/convertNewAPIProduct';

export const useFetchData = (getFunc, open, isMapData) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (!open) return;

    (async () => {
      try {
        setIsLoading(true);
        const getData = await getFunc();
        if (isValid(getData)) {
          setData(() => (isMapData ? mapDataProductV2({ product: getData?.data }) : getData?.data));
          setTotal(getData?.total);
        }
      } catch (error) {
        console.error('error', error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [open]);

  return { data, isLoading, error, total };
};
