import { getRegions } from 'clients/AddressClient';
import { useCallback } from 'react';
import { TTL_CACHE_LOCAL } from 'sysconfig';
import { CACHE_KEY, deserializeFunc, getWithExpiry, serializeFunc, setWithExpiry } from 'utils/localStorageUtils';

export function useRegions({ ttl = TTL_CACHE_LOCAL * 1000 } = {}) {
  const FIELDS = ['code', 'name', 'provinceCodes'];

  const getRegionByCode = useCallback(async (code) => {
    const data = await getRegionMapByCodes([code]);
    return Object.values(data)?.[0];
  }, []);

  const getRegionMapByCodes = useCallback(async (codes) => {
    const regions = {};

    // Step 1: Try to get cached data
    const missingCodes = [];
    const cachedRegions = getWithExpiry(CACHE_KEY.REGIONS) || {};
    for (const code of codes) {
      if (code === '00') continue; // Don't map region_code for TOANQUOC
      if (cachedRegions[code]) {
        const region = deserializeFunc(cachedRegions[code], FIELDS);
        regions[code] = region;
      } else {
        missingCodes.push(code);
      }
    }

    // Step 2: Fetch missing data, then cache
    if (missingCodes.length > 0) {
      const res = await getRegions({ codes: missingCodes });

      if (res.status !== 'OK') {
        console.error('[Error] Get regions', res);
      } else {
        res.data.forEach((region) => {
          const serializedData = serializeFunc(region, FIELDS);
          cachedRegions[region.code] = serializedData;
          regions[region.code] = deserializeFunc(serializedData, FIELDS);
        });

        // Update cache
        setWithExpiry(CACHE_KEY.REGIONS, cachedRegions, { ttl });
      }
    }

    return regions;
  }, []);

  return { getRegionByCode, getRegionMapByCodes };
}

