import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { isValid } from 'clients/Clients';
import { getMenuList } from 'clients/WebServiceClient';
import clsx from 'clsx';
import { ENUM_NOTIFICATION_TAB } from 'components-v2/mocules/NewNotification/interface';
import NotiComp from 'components-v2/mocules/NotiComp';
import SearchInput from 'components-v2/mocules/SearchInput';
import ToggleProfile from 'components-v2/mocules/ToggleProfile';
import LinkComp from 'components/atoms/LinkComp';
import { DEFAULT_THUOCSI_LONG_TITLE } from 'constants/data';
import { LEVEL_CUSTOMER } from 'constants/Enums';
import { AVATAR, HEART_HOME, HELP, HIRING, PAPER, THUOCSI_HEADER_LOGO_SVG, WHITE_HOUSE } from 'constants/Images';
import { HOME, MY_ACCOUNT, PATH_CAREER, PATH_NEWS } from 'constants/Paths';
import { useAuth } from 'context/Auth';
import { useNotify } from 'context/Notifications';
import { useRouter } from 'next/router';
import { memo, useEffect, useRef, useState } from 'react';
import { DOMAIN_FEEDBACK, DOMAIN_SELLER_CENTER } from 'sysconfig';
import gtag from 'utils/gtag';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import { useStore } from 'zustand-lib/storeGlobal';
import Navbar from '../Navbar';
import CountDownBar from './CountDownBar';
import NotificationV2 from './Notification';
import styles from './styles.module.css';

const KEY_DROPDOWN_LOCAL = 'dropDownMenuList';
const MINUTE_CACHE_MENU = 5;

const DEFAULT_MENU_PROFILE = {
  items: [
    {
      activeColor: '#15a959',
      activeIconUrl: 'https://cdn-gcs.thuocsi.vn/thuocsi-live/internal/cms/20246ba740922e7c9353359ffba9f70dIcon.svg',
      code: '2EEQ1PGE',
      color: '#333333',
      iconUrl: 'https://cdn-gcs.thuocsi.vn/thuocsi-live/internal/cms/2024aee7e46390bba5781d7cf6cd5392Icon-1.svg',
      isActive: true,
      label: 'Tài khoản của tôi',
      platform: 'WEB',
      priority: 1,
      systemDisplay: 'BUYMED',
      type: 'MENU_PROFILE',
      url: '/my-account',
    },
    {
      activeColor: '#15a959',
      activeIconUrl: 'https://cdn-gcs.thuocsi.vn/thuocsi-live/internal/cms/2024cc22f4c18f5dc848b92873c869c0Product_Cost.svg',
      code: '4T84FW96',
      color: '#333333',
      iconUrl: 'https://cdn-gcs.thuocsi.vn/thuocsi-live/internal/cms/20244180af43ac1ca5e1cdb5df436694Product_Cost-1.svg',
      isActive: true,
      label: 'Đơn hàng của tôi',
      platform: 'WEB',
      priority: 2,
      systemDisplay: 'BUYMED',
      type: 'MENU_PROFILE',
      url: '/my-order',
    },
    {
      activeColor: '#15a959',
      activeIconUrl: 'https://cdn-gcs.thuocsi.vn/thuocsi-live/internal/cms/202457835cb20dd863215c8ac22dd082diem_tich_luy_active.svg',
      code: '6U961PGE',
      color: '#333333',
      iconUrl: 'https://cdn-gcs.thuocsi.vn/thuocsi-live/internal/cms/20249410565fc83ff432dea126f4fda8dtl_non.svg',
      isActive: true,
      label: 'Điểm tích lũy',
      platform: 'WEB',
      priority: 3,
      systemDisplay: 'BUYMED',
      type: 'MENU_PROFILE',
      url: '/users/loyalty_points',
    },
    {
      activeColor: '#15a959',
      activeIconUrl: 'https://cdn-gcs.thuocsi.vn/thuocsi-live/internal/cms/2024019ec790f3dcc5814b1ee8cab352Message_4.svg',
      code: '7P2AXHR2',
      color: '#333333',
      iconUrl: 'https://cdn-gcs.thuocsi.vn/thuocsi-live/internal/cms/20240c5f136a9b1b9692d7e21927ba73phan_hoi_non_ac.svg',
      isActive: true,
      label: 'Phản hồi của tôi\t',
      platform: 'WEB',
      priority: 4,
      systemDisplay: 'BUYMED',
      type: 'MENU_PROFILE',
      url: '/users/my-ticket\t',
    },
    {
      activeColor: '#15a959',
      activeIconUrl: 'https://cdn-gcs.thuocsi.vn/thuocsi-live/internal/cms/2024d9bb427cd768e5eecc562578f2f2ct_tt.svg',
      code: '8AV84FW9',
      color: '#333333',
      iconUrl: 'https://cdn-gcs.thuocsi.vn/thuocsi-live/internal/cms/2024fcf7f1a1b808d3f8c39c9e5a8502ct_tt_non.svg',
      isActive: true,
      label: 'Chương trình trả thưởng',
      platform: 'WEB',
      priority: 5,
      systemDisplay: 'BUYMED',
      type: 'MENU_PROFILE',
      url: '/users/rewards\t',
    },
    {
      activeColor: '15A959',
      activeIconUrl: 'https://cdn-gcs.thuocsi.vn/thuocsi-live/internal/cms/2024521d938a6672f226bc320bf08c9fdang_xuat_ac.svg',
      code: '1WJYJYJY',
      color: '#333333',
      iconUrl: 'https://cdn-gcs.thuocsi.vn/thuocsi-live/internal/cms/2024da9fe07660ac55f017addf3fb57dFrame-3.svg',
      isActive: true,
      label: 'Đăng xuất',
      platform: 'WEB',
      priority: 6,
      systemDisplay: 'BUYMED',
      type: 'MENU_PROFILE',
      url: '/logout',
    },
  ],
};

function Logo() {
  return (
    <LinkComp href="/home" id="logo-thuocsi" padding="0px" title={DEFAULT_THUOCSI_LONG_TITLE}>
      <Box className={styles.logo}>
        <ImageFallbackStatic
          src={THUOCSI_HEADER_LOGO_SVG}
          width="127px"
          height="22px"
          quality={100}
          layout="fixed"
          alt={DEFAULT_THUOCSI_LONG_TITLE}
          title={DEFAULT_THUOCSI_LONG_TITLE}
        />
      </Box>
    </LinkComp>
  );
}

const HeadericonRight = memo(function HeadericonRight(props) {
  const { markAll, numberNotifyWithTab, fetchDataByTab } = useNotify();

  const { reloadDataCustomer } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [notiTab, setNotiTab] = useState(ENUM_NOTIFICATION_TAB.IMPORTANT);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (notiTab && numberNotifyWithTab?.[notiTab.toLowerCase()]?.unread > 0) {
      markAll(notiTab, false);
    }
    setAnchorEl(event?.currentTarget);
    reloadDataCustomer();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitchTab = (tab) => {
    setNotiTab(tab);
    fetchDataByTab(tab);
    if (ENUM_NOTIFICATION_TAB?.[tab] && numberNotifyWithTab?.[tab.toLowerCase()]?.unread > 0) {
      markAll(tab, false);
    }
  };

  const handleCustomCustomerLevel = (level) => {
    if (!level) return '';
    const upperCaseLevel = level.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
    return `Khách Hàng Hạng ${upperCaseLevel}`;
  };

  return (
    <Box className={styles.headerIconLogged}>
      <NotificationV2
        open={open}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        notiTab={notiTab}
        handleSwitchTab={handleSwitchTab}
      />
      <Box className={styles.profile}>
        <div id="profile__guide" className={styles.borderRadius}>
          <div className={styles.wrapperProfile}>
            <LinkComp href={MY_ACCOUNT} padding="0px">
              <Tooltip title={handleCustomCustomerLevel(LEVEL_CUSTOMER[props.user?.level]) || ''} arrow>
                <Box className={styles.avatar}>
                  <ImageFallbackStatic src={AVATAR} width="32px" height="32px" quality={100} layout="fixed" />
                </Box>
              </Tooltip>
            </LinkComp>
            {/* name of avatar when logged into web */}
            <Box className={styles.profile_name}>
              <Typography>{props.user?.name || ''}</Typography>
            </Box>
            <ToggleProfile user={props.user} point={props.point} level={props.level} listMenu={props?.listMenu} />
          </div>
        </div>
      </Box>
    </Box>
  );
});

const HeaderTop = memo(function HeaderTop({ isAuthenticated }) {
  return (
    <div className={styles.headerTop}>
      <div className={styles?.wrapperLink}>
        <LinkComp
          name="Đăng ký bán hàng"
          href={DOMAIN_SELLER_CENTER}
          target
          onClick={() => gtag.viewSeller()}
          removeStyles
          className={clsx(styles.linkMenu)}
        >
          <ImageFallbackStatic src={WHITE_HOUSE} width="24px" height="24px" quality={100} layout="fixed" />
        </LinkComp>
        <LinkComp name="Hướng dẫn đặt hàng" href={DOMAIN_FEEDBACK} removeStyles target onClick={() => gtag.viewBlog()} className={styles.linkMenu}>
          <ImageFallbackStatic src={HELP} width="24px" height="24px" quality={100} layout="fixed" />
        </LinkComp>
        {isAuthenticated && (
          <LinkComp name="Sản phẩm quan tâm" href="/user/wishlist" removeStyles onClick={() => gtag.viewBlog()} className={styles.linkMenu}>
            <ImageFallbackStatic src={HEART_HOME} width="24px" height="24px" quality={100} layout="fixed" />
          </LinkComp>
        )}
      </div>
      <div className={styles.wrapperLink}>
        {isAuthenticated && <NotiComp />}

        <LinkComp name="Tin tức" href={PATH_NEWS} target removeStyles onClick={() => gtag.viewBlog()} className={styles.linkMenu}>
          <ImageFallbackStatic src={PAPER} width="24px" height="24px" quality={100} layout="fixed" />
        </LinkComp>
        <LinkComp name="Tuyển dụng" href={PATH_CAREER} target removeStyles onClick={() => gtag.viewCareer()} className={styles.linkMenu}>
          <ImageFallbackStatic src={HIRING} width="24px" height="24px" quality={100} layout="fixed" />
        </LinkComp>
      </div>
    </div>
  );
});

const Header = memo(function Header({ balance = 0, pageName = '', point = 0 }) {
  const router = useRouter();
  const refItems = useRef([]);
  const hashtagTopSearch = useStore((state) => state.hashtagTopSearch);
  const [listMenu, setListMenu] = useState(DEFAULT_MENU_PROFILE);
  const [topSearch, setTopSearch] = useState([...hashtagTopSearch]);

  const currentUrl = router.asPath;
  const { user, isAuthenticated, toggleLogin, toggleSignUp } = useAuth();

  useEffect(async () => {
    const getListMenuLocal = JSON.parse(localStorage.getItem(KEY_DROPDOWN_LOCAL));
    const fetchData = async () => {
      const menuListRes = await getMenuList({});
      if (isValid(menuListRes)) {
        setListMenu((prevState) => (menuListRes?.data?.[0]?.items?.length === 6 ? menuListRes?.data?.[0] : prevState));
        const expiry = new Date().getTime() + MINUTE_CACHE_MENU * 60 * 1000;
        localStorage.setItem(KEY_DROPDOWN_LOCAL, JSON.stringify({ data: menuListRes?.data[0], dayExpired: expiry }));
      }
    };
    if (getListMenuLocal) {
      const currentTime = new Date().getTime();
      if (getListMenuLocal.dayExpired < currentTime) {
        fetchData();
      } else {
        setListMenu(getListMenuLocal?.data?.items?.length === 6 ? getListMenuLocal?.data : DEFAULT_MENU_PROFILE);
      }
    } else {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (hashtagTopSearch?.length > 0 && refItems && isAuthenticated) {
      const container = refItems.current;
      let indexOverflowing = hashtagTopSearch?.length;
      if (container) {
        let widthContainer = 0;
        for (let index = 0; index < container?.childNodes?.length; index += 1) {
          const child = container.childNodes[index];
          widthContainer += child.clientWidth + 18;
          if (widthContainer > 605) {
            indexOverflowing = index;
            break;
          }
        }
        const dataNew = [...topSearch]?.slice(0, indexOverflowing);
        dataNew.forEach((item) => {
          gtag.displayTopSearchOutside(item);
        });
        setTopSearch(dataNew);
      }
    }
  }, [refItems, hashtagTopSearch, isAuthenticated]);

  const renderUnAuth = () => (
    <Box className={styles.headerCenter_Status}>
      <ButtonBase data-test="btn-signup" className={styles.register} onClick={toggleSignUp} disableRipple>
        ĐĂNG KÝ
      </ButtonBase>
      <ButtonBase data-test="btn-signin" className={styles.login} onClick={toggleLogin} disableRipple>
        ĐĂNG NHẬP
      </ButtonBase>
    </Box>
  );

  const renderAuth = () => (
    <>
      <div id="search__homepage" className={styles.searchBar}>
        <SearchInput className={styles.SearchInput} />
        <div className={styles.listSearch} ref={refItems}>
          {topSearch?.map((item) => (
            <LinkComp
              data-test="search-outside"
              href={item.url}
              onClick={() => gtag.clickTopSearchOutside(item)}
              className={styles.mostSearchItem}
              key={item?.code}
              removeStyles
            >
              <span>{item?.hashtag}</span>
            </LinkComp>
          ))}
        </div>
      </div>
      <HeadericonRight balance={balance} point={user?.point} level={user?.level} user={user} listMenu={listMenu} />
    </>
  );

  return (
    <>
      <div className={styles?.containerHeader}>
        <div className={styles?.topMenuContainer}>
          <HeaderTop
            isAuthenticated={isAuthenticated}
            isShowAnnouncement={user?.isCashback && [HOME].includes(router?.pathname)}
            currentUrl={currentUrl}
          />
        </div>
        <div className={styles.headerCenter}>
          <CountDownBar />
        </div>
      </div>
      <div className={clsx(styles.stickyHeaderSearch)}>
        <Container className={styles.headerCenter_wrapper}>
          <Logo />
          {router?.pathname?.includes('/loading') ? <></> : isAuthenticated ? renderAuth() : renderUnAuth()}
        </Container>
      </div>
      {isAuthenticated && (
        <div className={clsx(styles.stickyHeaderMenu, topSearch?.length > 0 && styles?.stickyTopSearch)}>
          <Navbar pageName={pageName} point={point} balance={balance} />
        </div>
      )}
    </>
  );
});

export default Header;
