import { GET, POST, PUT } from './Clients';

const PRODUCT_API_PREFIX = '/marketplace/product/v2';
const FRONTEND_APIS_PREFIX = '/marketplace/frontend-apis/v1';

export async function getScreenWishlist({ ctx, offset = 0, limit = 20, search, getTotal = false }) {
  return GET({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/screen/product/wishlist`,
    params: {
      search,
      limit,
      offset,
      getTotal,
      queryOption: `isReplacePriceAfterVoucher`,
      ...ctx?.query,
    },
  });
}

export async function removeWishlist(body) {
  return PUT({
    url: `${PRODUCT_API_PREFIX}/me/wishlist`,
    body,
  });
}

export async function getMeWishlist({ offset = 0, limit = 10, getTotal = false } = {}) {
  return GET({
    url: `${FRONTEND_APIS_PREFIX}/me/wishlist`,
    params: {
      limit,
      offset,
      getTotal,
      queryOption: 'isReplacePriceAfterVoucher',
    },
  });
}

export async function updateWishlist(sku) {
  return POST({
    url: `${PRODUCT_API_PREFIX}/me/wishlist`,
    body: { sku },
  });
}

export async function deleteItemWishlist(sku) {
  return PUT({
    url: `${PRODUCT_API_PREFIX}/me/wishlist`,
    body: { sku },
  });
}

export async function getListSkuByProductId({ ctx, productIds }) {
  return GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/sku/list-by-product`,
    params: { productIds },
  });
}
