import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import NotificationTabs from 'components-v2/layout/Header/Notification/Tabs';
import { ENUM_NOTIFICATION_TAB } from 'components-v2/mocules/NewNotification/interface';
import { NOTI } from 'constants/Images';
import { useAuth } from 'context/Auth';
import { useNotify } from 'context/Notifications';
import { useState } from 'react';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import styles from './styles.module.css';

export default function NotiComp() {
  const { markAll, numberNotifyWithTab, fetchDataByTab, unread: unreadNotification } = useNotify();
  const { reloadDataCustomer } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [notiTab, setNotiTab] = useState(ENUM_NOTIFICATION_TAB.IMPORTANT);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (notiTab && numberNotifyWithTab?.[notiTab.toLowerCase()]?.unread > 0) {
      markAll(notiTab, false);
    }
    setAnchorEl(event?.currentTarget);
    reloadDataCustomer();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitchTab = (tab) => {
    setNotiTab(tab);
    fetchDataByTab(tab);
    if (ENUM_NOTIFICATION_TAB?.[tab] && numberNotifyWithTab?.[tab.toLowerCase()]?.unread > 0) {
      markAll(tab, false);
    }
  };

  return (
    <>
      <Menu
        id="newNotifyMenu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        getContentAnchorEl={null}
        TransitionComponent={Fade}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            transform: 'translateY(5px)', // 5px is a padding bottom of header
          },
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ paper: clsx(styles.notifyWrap, styles.newNotificationMenu) }}
      >
        <Box className={styles.notifyContentTop}>
          <Box>
            <Typography className={styles.notifyTitle} variant="h5">
              Thông báo
            </Typography>
          </Box>
        </Box>
        <NotificationTabs notiTab={notiTab} handleSwitchTab={handleSwitchTab} />
      </Menu>
      <ButtonBase className={styles.notiContainer} onClick={handleClick}>
        <div className={styles?.badgeContainer}>
          <ImageFallbackStatic src={NOTI} width="14px" height="18px" quality={100} layout="fixed" />
          {unreadNotification > 0 ? <span className={styles?.badge}>{unreadNotification > 99 ? '99+' : unreadNotification}</span> : <></>}
        </div>
        <span className={styles?.titleNoti}>Thông báo</span>
      </ButtonBase>
    </>
  );
}
