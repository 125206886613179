import Portal from '@material-ui/core/Portal';
import clsx from 'clsx';
import React from 'react';
import styles from './styles.module.css';

interface Props {
  isOpen: boolean;
  level?: 1 | 2;
}

export default function OverlayV2({ isOpen, level = 2 }: Props) {
  if (!isOpen) return null;

  return (
    <Portal>
      <div className={clsx(styles.MenuOverlay, styles[`level-${level}`], { [styles.fadeIn]: isOpen, [styles.fadeOut]: !isOpen })} />
    </Portal>
  );
}
