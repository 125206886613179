import { getCountdownBarList } from 'clients/MarketingClient';
import { useAuth } from 'context/Auth';
import { useEffect, useState } from 'react';
import { TTL_CACHE_SERVER } from 'sysconfig';
import { CACHE_KEY, getWithExpiry, setWithExpiry } from 'utils/localStorageUtils';
import { useRegions } from './useRegions';

export function useCountdownBars({ ttl = TTL_CACHE_SERVER * 1000 } = {}) {
  const [countdownBarList, setCountdownBarList] = useState([]);

  const { getRegionMapByCodes } = useRegions();
  const { user } = useAuth();

  useEffect(() => {
    (async () => {
      const key = CACHE_KEY.COUNTDOWNBAR + user?.provinceCode;

      let countdownBars = getWithExpiry(key);

      if (!countdownBars) {
        countdownBars = await getActiveCountdownBars(getRegionMapByCodes, user);
        setWithExpiry(key, countdownBars, { ttl });
      }

      setCountdownBarList(countdownBars);
    })();
  }, [user]);

  return { countdownBarList };
}

async function getActiveCountdownBars(getRegionMapByCodes, user) {
  // Get active countdownBar
  const countdownBarRes = await getCountdownBarList();
  const countdownBarList = (countdownBarRes?.data || [])
    .filter((item) => {
      if (!item?.isActive) return false;

      const currentTime = new Date();
      const startDisplayTime = new Date(item.startDisplayTime);
      if (currentTime < startDisplayTime) return false;

      const endDisplayTime = new Date(item.endDisplayTime);
      if (currentTime > endDisplayTime) return false;

      return true;
    })
    .sort((cd1, cd2) => new Date(cd2?.startDisplayTime) - new Date(cd1?.startDisplayTime));

  // Get regions
  const regionCodesToFetch = countdownBarList.map((c) => c.regionCodes || []).flat();
  const mapRegionWithProvinces = await getRegionMapByCodes(regionCodesToFetch);

  // Filter countdown bar by nationwide and user's province
  const nationwideCountdownBar = [];
  const userProvinceCountdownBar = [];
  countdownBarList.forEach(
    ({ imageType = '', imageUrl = '', name = '', type = '', url = '', code = '', backgroundColor = '', countdownbarID = '', regionCodes = [] }) => {
      let provinceCodes = [];
      regionCodes.forEach((regionCode) => {
        provinceCodes = provinceCodes.concat(mapRegionWithProvinces[regionCode]?.provinceCodes || []);
      });

      const item = {
        imageType,
        imageUrl,
        name,
        type,
        url,
        code,
        backgroundColor,
        countdownbarID,
        regionCodes,
        provinceCodes,
      };

      if (item.provinceCodes?.includes(user?.provinceCode)) {
        userProvinceCountdownBar.push(item);
      } else if (item.regionCodes?.includes('00')) {
        nationwideCountdownBar.push(item);
      }
    },
  );

  return [...userProvinceCountdownBar, ...nationwideCountdownBar];
}
