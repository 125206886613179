import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import Portal from '@material-ui/core/Portal';
import Typography from '@material-ui/core/Typography';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { isValid } from 'clients/Clients';
import { getMeWishlist } from 'clients/WishlistClient';
import clsx from 'clsx';
import LinkComp from 'components/atoms/LinkComp';
import NewCustomModal from 'components/mocules/NewCustomModal';
import {
  ARROW,
  DEBT,
  DIAMOND_PROFILE,
  GOLD_PROFILE,
  GUIDE_WISH_ITEM,
  HEART,
  HERE,
  PLATINUM_PROFILE,
  REWARD_PROFILE,
  SLIVER_PROFILE,
} from 'constants/Images';
import { SVGPrice } from 'constants/SVG';
import { useAuth } from 'context/Auth';
import { useCart } from 'context/Cart/CartContext';
import { useFetchData } from 'hooks/useFetchData';
import useModal from 'hooks/useModal';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useRef, useState } from 'react';
import mapDataProductV2, { getSKUErrorMessage } from 'services/convertNewAPIProduct';
import { getDebt } from 'services/UserService';
import FormatNumber, { formatCurrency } from 'utils/FormatNumber';
import ImageFallback, { ImageFallbackProductImage, ImageFallbackStatic } from 'utils/ImageFallback';
import { useStore } from 'zustand-lib/storeGlobal';
import styles from './styles.module.css';

const isMapData = true;

const OBJECT_IMAGE = {
  LEVEL_SILVER: <ImageFallbackStatic src={SLIVER_PROFILE} width="61" height="28" alt="Bạc" objectFit="contain" />,
  LEVEL_GOLD: <ImageFallbackStatic src={GOLD_PROFILE} width="69" height="28" alt="Vàng" objectFit="contain" />,
  LEVEL_PLATINUM: <ImageFallbackStatic src={PLATINUM_PROFILE} width="91" height="28" alt="Bạch kim" objectFit="contain" />,
  LEVEL_DIAMOND: <ImageFallbackStatic src={DIAMOND_PROFILE} width="101" height="28" alt="Kim cương" objectFit="contain" />,
};
const WishListItem = ({ product, handleClickLink }) => {
  const appSetting = useStore((state) => state.appSetting);
  const errorMessageProduct = getSKUErrorMessage(product, appSetting);

  const hasError = errorMessageProduct || !product.salePrice || product.status === 'STOP_SELLING' || product.salePrice === 0;

  return (
    <LinkComp href={`/product/${product?.slug}`} onClick={handleClickLink} className={styles?.wishListItem} removeStyles>
      <ImageFallbackProductImage
        src={product?.defaultImage}
        width="70"
        height="70"
        alt="Hình ảnh sản phẩm"
        objectFit="contain"
        fallbackSrc={product?.defaultImage}
        quality={100}
      />
      <div className={styles?.infoProduct}>
        <span className={styles?.name}>{product?.name}</span>
        <span className={styles?.volume}>{product?.volume}</span>
        {hasError ? (
          <Typography className={styles.error}>
            {product.requireGPPMessage || errorMessageProduct || product?.errorMessage || 'Đang cập nhật giá'}
          </Typography>
        ) : (
          <SVGPrice displayPriceFormated={product?.displayPriceFormated || 0} size="sm" fillPath="#09884D" />
        )}
      </div>
    </LinkComp>
  );
};

export default function ToggleProfile({ point, level, classCustom, listMenu, user, ...restProps }) {
  const router = useRouter();
  const divRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [wishList, setWishList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPoupLogout, toggleLogout] = useModal(false);

  const { data, isLoading, total } = useFetchData(getMeWishlist, open, isMapData);
  const { clearCart } = useCart();
  const { logout } = useAuth();

  // lazy load: only get debt when open profile
  const [debt, setDebt] = useState(null);
  useEffect(() => {
    if (!open) return;
    if (debt) return;

    (async () => {
      setDebt(await getDebt(user));
    })();
  }, [open]);

  const handleClick = () => {
    setOpen((value) => !value);
  };

  const handleLogout = () => {
    logout(() => {}, { clearAll: true });
    clearCart();
  };

  const handleMouseEnter = (index) => {
    setActiveTab(index);
  };

  const renderWishList = (data) => {
    if (data?.length > 0) {
      return data?.map((item) => <WishListItem key={item?.slug} product={item} handleClickLink={() => setOpen(false)} />);
    }
    return (
      <div className={styles?.emptyContainer}>
        <ImageFallbackStatic src={GUIDE_WISH_ITEM} width="210" height="210" alt="image" objectFit="contain" />
        <div className={styles?.wrapperHereGif}>
          <ImageFallbackStatic src={HERE} width="56" height="42" alt="image" objectFit="contain" />
        </div>
        <span className={styles?.textGuide}>
          Hãy nhấn vào biểu tượng <span className={styles?.boldText}>“Trái tim” trên thẻ sản phẩm</span> để thêm sản phẩm quan tâm bạn nhé
        </span>
      </div>
    );
  };

  const handleScroll = async () => {
    const div = divRef.current;

    if (div.scrollTop + div.clientHeight === div.scrollHeight) {
      if (wishList?.length < total && !loading) {
        setLoading(true);
        try {
          const loadMoreData = await getMeWishlist({ limit: 10, offset: wishList?.length });
          if (isValid(loadMoreData)) {
            setWishList((prevData) => [...prevData, ...mapDataProductV2({ product: loadMoreData?.data })]);
          }
        } catch (error) {
          setLoading(false);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const handleQuickAccessLoyaltyPoints = () => {
    router.push('/users/loyalty_points');
  };

  useEffect(() => {
    if (data?.length > 0) {
      setWishList(data);
    }
  }, [data]);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box {...restProps} className={styles?.profileContainer}>
        <IconButton className={clsx(styles.iconInfo, classCustom)} onClick={handleClick} data-test="btn-toggle-profile">
          <ExpandMore />
        </IconButton>
        {open && (
          <div className={styles?.profilePopup}>
            <div className={styles?.wishListContainer}>
              <span className={styles?.wishListTitle}>
                <span className={styles?.textTitle}>
                  <ImageFallbackStatic src={HEART} width="24" height="24" alt="Trái tim" quantity={100} objectFit="contain" />
                  Sản phẩm quan tâm
                </span>
                <ButtonBase className={styles?.buttonBase} onClick={() => router.push('/user/wishlist')}>
                  Xem thêm <ImageFallbackStatic src={ARROW} width="16" height="16" alt="Mũi tên" quantity={100} objectFit="contain" />
                </ButtonBase>
              </span>
              <div ref={divRef} onScroll={handleScroll} className={clsx(styles?.wishListContent, isLoading && styles?.isLoading)}>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <div className={styles?.renderWishList}>
                    {renderWishList(wishList)}
                    {loading && (
                      <div className={styles?.wrapperLoading}>
                        <CircularProgress />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className={styles?.menuListContainer}>
              <div className={styles?.levelContainer} onClick={handleQuickAccessLoyaltyPoints}>
                <div className={styles?.levelIcon}>
                  <span className={styles?.leftContent}>
                    {debt?.isValid ? (
                      <>
                        <span className={styles?.debtContent}>
                          <ImageFallbackStatic src={DEBT} width="20" height="20" alt="Debt" quantity={100} objectFit="contain" /> Hạn Mức
                        </span>
                        <span className={styles?.debtValue}>{formatCurrency(debt.balance)}</span>
                      </>
                    ) : (
                      <>
                        Cấp bậc
                        {OBJECT_IMAGE[level]}
                      </>
                    )}
                  </span>

                  <span className={styles?.customHr} />
                  <span className={styles?.rightContent}>
                    Điểm tích lũy{' '}
                    <span className={styles?.score}>
                      <ImageFallbackStatic src={REWARD_PROFILE} width="24" height="24" alt="Huy chương" objectFit="contain" />
                      {FormatNumber.formatFloatNumber(point)}
                    </span>
                  </span>
                </div>
              </div>
              <div className={styles?.menuContainer}>
                {listMenu?.items?.map((x, index) => (
                  <Fragment key={x.label}>
                    {x?.url !== '/logout' ? (
                      <LinkComp
                        key={x?.url}
                        href={x?.url}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseOut={() => setActiveTab(null)}
                        className={styles?.menuItem}
                        removeStyles
                      >
                        {activeTab === index ? (
                          <ImageFallback src={x?.activeIconUrl} width="24" height="24" objectFit="contain" />
                        ) : (
                          <ImageFallback src={x?.iconUrl} width="24" height="24" objectFit="contain" />
                        )}
                        <span className={styles?.textLabel}>{x?.label}</span>
                      </LinkComp>
                    ) : (
                      <ButtonBase
                        key={x?.url}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseOut={() => setActiveTab(null)}
                        onClick={toggleLogout}
                        className={styles?.menuItem}
                      >
                        {activeTab === index ? (
                          <ImageFallback src={x?.activeIconUrl} width="24" height="24" objectFit="contain" />
                        ) : (
                          <ImageFallback src={x?.iconUrl} width="24" height="24" objectFit="contain" />
                        )}
                        <span className={styles?.textLabel}>{x?.label}</span>
                      </ButtonBase>
                    )}
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        )}

        <NewCustomModal
          visible={showPoupLogout}
          onClose={toggleLogout}
          title="Xin xác nhận"
          content="Bạn có chắc muốn đăng xuất?"
          btnOk="Có"
          onClickOk={handleLogout}
          btnOnClose="Không"
        />
        {open && (
          <Portal>
            <ButtonBase disableRipple className={styles?.backdrop} onClick={() => setOpen(false)} />
          </Portal>
        )}
      </Box>
    </ClickAwayListener>
  );
}
